import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from "styled-components";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const Register = (props) => {
    const [registrationFormData, setRegistrationFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        gender : '',
        password: '',
        confirmPassword: '',
        domain: 'www.cab.yesbus.in'
    });
    const [registnFormValidation, setregistnFormValidation] = useState({
        firstNameerrorStatus: false,
        lastNameerrorStatus: false,
        emailerrorStatus: false,
        mobileerrorStatus: false,
        passworderrorStatus: false,
        confirmPassworderrorStatus: false,
        errorMessage: ''
    });
    const [isRegistering, setIsRegistering] = useState(false); // Flag to track registration process

    const registerUser = async () => {
        console.log(registrationFormData);
        const apiUrl = process.env.REACT_APP_API_URL;
        const apiKey = process.env.REACT_APP_API_KEY;
        const apiToken = process.env.REACT_APP_API_TOKEN;
        try {
            const response = await axios.post(apiUrl + '/register', registrationFormData, {
            headers: {
                'YESBUS_API_KEY': apiKey,
                Authorization: `Bearer ${apiToken}`,
            }
            });
            console.log(response);
            if (response.data.status === 'success') {
            // Registration successful
            //localStorage.setItem('yb_user_token', JSON.stringify(response.data.token));
            // Redirect to the login page
            props.setSignInPopup(false);
            props.setLoginPopup(true);
            }
        } catch (error) {
            console.error('Error registering user:', error);
        } finally {
            setIsRegistering(false); // Reset the flag after API request completion
        }
    };

    const handleRegistration = (e) => {
        e.preventDefault();
        const fields = {
            firstName: 'Please enter first name',
            lastName: 'Please enter email',
            email : 'Please enter email',
            mobile : 'Please enter mobile',
            password : 'Please enter password',
            confirmPassword : 'Please enter confirm password'
        };
        for (const [key, value] of Object.entries(fields)) {
            if (registrationFormData[key] === '') {
                setregistnFormValidation((prevState) => ({
                    ...prevState,
                    [key + "errorStatus"]: true,
                    errorMessage: value
                  }));
              return false;
            }else{
                setregistnFormValidation((prevState) => ({
                    ...prevState,
                    [key + "errorStatus"]: false,
                    errorMessage: ''
                }));
            }
        }
        if (registrationFormData.password !== registrationFormData.confirmPassword) {
            setregistnFormValidation({...registnFormValidation,confirmPassworderrorStatus:true,errorMessage:'Password and confirm password do not match'});
        }else{
            setregistnFormValidation({...registnFormValidation,confirmPassworderrorStatus:false,errorMessage:''});
            setIsRegistering(true);
            // Call the registerUser function to make the API request
            registerUser();
        }
    };
    return (
        <>
            <SignupSideBar style={props.signinPopup===true && props.loginPopup===false ? {display:''} : {display:'none'}} className='sidebar_sec'>
                <div className='modal-down-bg-sec'></div>
                <div className="modal-login-bg-sec">
                    <div className="login_signup_menu">
                        <div className="login_signup_menu_close new_close_round">
                            <Link onClick={()=>props.setSignInPopup(false)}><FontAwesomeIcon icon={faTimes} /></Link>
                        </div>
                        <div className='title_head'>
                            <h6>Looks like you're new here!</h6>
                            <h5>Sign up with your details to get started</h5>
                        </div>
                        <div className='login_pass_sec'>
                            <form onSubmit={handleRegistration}>
                                <div className='form_inrner_div'>
                                    <div className='form_input_sec'>
                                        <span className="passwrod_sec_inner_cont_input span_of_input">
                                            <FontAwesomeIcon icon={faUser} />
                                            <input type="text" name="firstName" value={registrationFormData.firstName} onChange={(e)=>{setRegistrationFormData({...registrationFormData,firstName:e.target.value});setregistnFormValidation({...registnFormValidation,firstNameerrorStatus:false})}} placeholder="Enter First Name"/>
                                            <span className="input_fld_requierd login-error" id="firstName_error" style={registnFormValidation.firstNameerrorStatus===true ? {display:''} : {display:'none'}}>{registnFormValidation.errorMessage}</span>
                                        </span>
                                    </div>
                                    <div className='form_input_sec'>
                                        <span className="passwrod_sec_inner_cont_input span_of_input">
                                            <FontAwesomeIcon icon={faUser} />
                                            <input type="text" name="lastName" value={registrationFormData.lastName} onChange={(e)=>{setRegistrationFormData({...registrationFormData,lastName:e.target.value});setregistnFormValidation({...registnFormValidation,lastNameerrorStatus:false})}} placeholder="Enter Last Name"/>
                                        </span>
                                        <span className="input_fld_requierd login-error" id="lastName_error" style={registnFormValidation.lastNameerrorStatus===true ? {display:''} : {display:'none'}}>{registnFormValidation.errorMessage}</span>
                                    </div>
                                    
                                </div>
                                
                                <div className='form_inrner_div'>
                                    <div className='form_input_sec'>
                                        <span className="passwrod_sec_inner_cont_input span_of_input">
                                            <FontAwesomeIcon icon={faEnvelope} />
                                            <input type="text" name="email" value={registrationFormData.email} onChange={(e)=>{setRegistrationFormData({...registrationFormData,email:e.target.value});setregistnFormValidation({...registnFormValidation,emailerrorStatus:false})}} placeholder="Enter Email ID"/>
                                            <span className="input_fld_requierd login-error" id="email_error" style={registnFormValidation.emailerrorStatus===true ? {display:''} : {display:'none'}}>{registnFormValidation.errorMessage}</span>
                                        </span>
                                    </div>
                                    <div className='form_input_sec'>
                                        <span className="passwrod_sec_inner_cont_input span_of_input">
                                            <FontAwesomeIcon icon={faPhone} />
                                            <input type="text" name="mobile" value={registrationFormData.mobile} onChange={(e)=>{setRegistrationFormData({...registrationFormData,mobile:e.target.value});setregistnFormValidation({...registnFormValidation,mobileerrorStatus:false})}} placeholder="Enter Mobile Number"/>
                                            <span className="input_fld_requierd login-error" id="mobile_error" style={registnFormValidation.mobileerrorStatus===true ? {display:''} : {display:'none'}}>{registnFormValidation.errorMessage}</span>
                                        </span>
                                    </div>
                                </div>
                                <div className='form_inrner_div_radio'>
                                    <div className='form_input_sec_radio'>
                                        <span className="span_of_radio">
                                            <input type="radio" name="gender" id="for_male" value='0' checked={registrationFormData.gender === '0'} onChange={(e) => setRegistrationFormData({...registrationFormData,gender:e.target.value})}/>
                                            <label htmlFor="for_male">Male</label>
                                        </span>
                                    </div>
                                    <div className='form_input_sec_radio'>
                                        <span className="span_of_radio">
                                            <input type="radio" name="gender" id="for_female" value='1' checked={registrationFormData.gender === '1'} onChange={(e) => setRegistrationFormData({...registrationFormData,gender:e.target.value})}/>
                                            <label htmlFor="for_female">Female</label>
                                        </span>
                                    </div>
                                    <div className='form_input_sec_radio'>
                                        <span className="span_of_radio">
                                            <input type="radio" name="gender" id="for_other" value='2' checked={registrationFormData.gender === '2'} onChange={(e) => setRegistrationFormData({...registrationFormData,gender:e.target.value})}/>
                                            <label htmlFor="for_other">Other</label>
                                        </span>
                                    </div>
                                </div>
                                <div className='form_inrner_div'>
                                    <div className='form_input_sec'>
                                        <span className="passwrod_sec_inner_cont_input span_of_input">
                                            <FontAwesomeIcon icon={faLock} />
                                            <input type="password" name="password" value={registrationFormData.password} onChange={(e)=>{setRegistrationFormData({...registrationFormData,password:e.target.value});setregistnFormValidation({...registnFormValidation,passworderrorStatus:false})}} className="login_password" placeholder="Enter Password"/>
                                            <i className="fas fa-eye-slash"></i>
                                            <span className="input_fld_requierd login-error" style={registnFormValidation.passworderrorStatus===true ? {display:''} : {display:'none'}}>{registnFormValidation.errorMessage}</span>
                                        </span>
                                    </div>
                                    <div className='form_input_sec'>
                                        <span className="passwrod_sec_inner_cont_input span_of_input">
                                            <FontAwesomeIcon icon={faLock} />
                                            <input type="password" name="confirmPassword" value={registrationFormData.confirmPassword} onChange={(e)=>{setRegistrationFormData({...registrationFormData,confirmPassword:e.target.value});setregistnFormValidation({...registnFormValidation,confirmPassworderrorStatus:false})}} className="login_password" placeholder="Confirm Password"/>
                                            <i className="fas fa-eye-slash"></i>
                                            <span className="input_fld_requierd login-error" style={registnFormValidation.confirmPassworderrorStatus===true ? {display:''} : {display:'none'}}>{registnFormValidation.errorMessage}</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="login_btns_sec">
                                    <ul>
                                        <li>
                                            <button type="submit" className="login_btn">{isRegistering===true ? 'Wait...' : 'Sign Up'}</button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="or_sec_text"><span>OR</span></div>
                                <div className='login_diff_sec'>
                                    <a href="">
                                        <img src="https://i.stack.imgur.com/oL5c2.png" alt="" />
                                    </a>
                                </div>
                                <div className="create_acc_sec">
                                Already have an account? <Link onClick={()=>{props.setSignInPopup(false);props.setLoginPopup(true)}}> Log in</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </SignupSideBar>
        </>
    )
}
const SignupSideBar = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    ${'' /* background-color: #fff; */}
    z-index: 9999;
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    .modal-down-bg-sec {
        background: #0000004a;
    }
    .modal-login-bg-sec {
        background: #fff;
        .login_signup_menu{
            padding: 10px 30px 0px 30px;
            .new_close_round{
                font-size: 30px;
                svg{
                    color: #000;
                }
            }
            .title_head {
                h6{
                    text-align: center;
                    color: #2d318d;
                    font-weight: 500;
                    font-size: 22px;
                    margin-bottom: 10px;
                }
                h5 {
                    position: relative;
                    font-size: 27px;
                    font-weight: 300;
                    line-height: inherit;
                    margin-bottom: 30px;
                    text-align: center;
                }
            }
            .login_pass_sec{
                form{
                    .form_inrner_div{
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-around;
                    }
                    .form_inrner_div_radio{
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        justify-content: flex-start;
                        padding-left: 30px;
                    }
                    .form_input_sec{
                        padding-top: 20px;
                        .span_of_input{
                            position: relative;
                            
                            svg{
                                position: absolute;
                                left: 0;
                                top: 0;
                                z-index: 1;
                                color: #2d318d;
                                font-size: 22px;
                                border-right: 1.5px solid #ccc;
                                padding-right: 15px;
                            }
                            input{
                                position: relative;
                                width: 100%;
                                height: 42px;
                                border: none;
                                border-bottom: 1.5px solid #2d318d;
                                color: #000000;
                                font-size: 18px;
                                font-weight: 400;
                                padding-left: 50px !important;
                            }
                            

                        }
                        .input_fld_requierd {
                            position: absolute;
                            color: #FF4E4E;
                            font-size: 15px;
                            font-weight: 400;
                            text-align: center;
                            background: #FFFFFF 0% 0% no-repeat padding-box;
                            box-shadow: 0px 1px 10px #00000029;
                            border: 1px solid #FFB0B0;
                            height: 40px;
                            padding: 0 15px;
                            left: auto;
                            right: auto;
                            z-index: 11;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: auto;
                            top: auto;
                        }
                        .input_fld_requierd:after {
                            content: '';
                            position: absolute;
                            right: 0;
                            left: 50%;
                            top: -9px;
                            font-family: FontAwesome;
                            height: 15px;
                            width: 15px;
                            background: #fff;
                            border-top: 1px solid #ffb0b0;
                            border-left: 1px solid #ffb0b0;
                            transform: rotate(45deg);
                            z-index: -11;
                        }
                    }
                    .form_input_sec_radio{
                        padding-top: 30px;
                        .span_of_radio{
                            display: flex;
                            grid-gap: 10px;
                            ${'' /* label{
                                font-size: 18px;
                            } */}
                            [type="radio"]:checked,
                            [type="radio"]:not(:checked) {
                                position: absolute;
                                left: -9999px;
                            }
                            [type="radio"]:checked + label,
                            [type="radio"]:not(:checked) + label
                            {
                                position: relative;
                                padding-left: 28px;
                                cursor: pointer;
                                line-height: 20px;
                                display: inline-block;
                                color: rgb(46 49 136);
                                font-weight: 500;
                            }
                            [type="radio"]:checked + label:before,
                            [type="radio"]:not(:checked) + label:before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 18px;
                                height: 18px;
                                border: 1px solid rgb(46 49 136);
                                border-radius: 100%;
                                background: #fff;
                            }
                            [type="radio"]:checked + label:after,
                            [type="radio"]:not(:checked) + label:after {
                                content: '';
                                width: 12px;
                                height: 12px;
                                background: rgb(46 49 136);
                                position: absolute;
                                top: 3px;
                                left: 3px;
                                border-radius: 100%;
                                -webkit-transition: all 0.2s ease;
                                transition: all 0.2s ease;
                            }
                            [type="radio"]:not(:checked) + label:after {
                                opacity: 0;
                                -webkit-transform: scale(0);
                                transform: scale(0);
                            }
                            [type="radio"]:checked + label:after {
                                opacity: 1;
                                -webkit-transform: scale(1);
                                transform: scale(1);
                            }
                        }
                    }
                    .login_btns_sec{
                        padding-top: 50px;
                        text-align: center;
                        .login_btn{
                            display: inline-block;
                            font-weight: 600;
                            text-transform: uppercase;
                            position: relative;
                            background: rgb(45, 49, 141);
                            -webkit-box-align: center;
                            align-items: center;
                            -webkit-box-pack: center;
                            justify-content: center;
                            color: rgb(255, 255, 255);
                            border-radius: 5px;
                            padding: 7px 25px;
                            font-size: 14px;
                            transition: all 0.5s ease-in-out 0s;
                            border: 1.5px solid rgb(45, 49, 141);
                        }
                        .login_btn:hover{
                            background: #fff;
                            color: rgb(45, 49, 141);
                        }
                    }
                    .or_sec_text{
                        position: relative;
                        padding-left: 0px;
                        margin: 30px 0px 0px;
                        text-align: center;
                        span {
                            font-size: 20px;
                            font-weight: 500;
                            color: rgb(139, 139, 139);
                            margin: 10px 0px 15px;
                            position: relative;
                        }
                        span::before {
                            position: absolute;
                            content: "";
                            right: 40px;
                            top: 13px;
                            height: 1.5px;
                            width: 80px;
                            background: rgb(139, 139, 139);
                        }
                        span::after {
                            position: absolute;
                            content: "";
                            left: 40px;
                            top: 13px;
                            height: 1.5px;
                            width: 80px;
                            background: rgb(139, 139, 139);
                        }
                    }
                    .login_diff_sec{
                        text-align: center;
                        padding-top: 30px;
                        img{
                            width: 50%;
                        }
                    }
                    .create_acc_sec{
                        padding-top: 40px;
                        font-size: 18px;
                        text-align: center;
                        a{
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 767px) { 
        grid-template-columns: 1fr;
        
    }
    @media (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: 1fr 2fr;
    }
`;
export default Register