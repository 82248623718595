import React,{useState, useEffect} from 'react';

const SeatLayoutMatrixMob = (props) => {
    //const [seatSelects,setSeatSelects] = useState([]);
    // const bookedSeats = props.bookedSeats;
    const bookedSeats = props.bookedSeats.split(',');
    const seatObject = {};
    bookedSeats.forEach(entry => {
        const [seat, value] = entry.split(':');
        seatObject[seat] = parseInt(value, 10);
    });
    const setSeatMob = (seatName,seatFor,seatFare,seatBaseFare,seatType,busid) => {
        let bookingInfo = JSON.parse(localStorage.getItem('bookingDetails'));
        if(bookingInfo){
            let seatAry = bookingInfo[props.bus_id]['seats'];
            const isSeatSelected = seatAry.some((seat) => seat.seatName === seatName);
            if (isSeatSelected) {
                document.getElementById(`seat_layout_${seatName+busid}`).classList.remove('selected');
                seatAry = seatAry.filter((seat) => seat.seatName !== seatName);
            } else {
                if (seatAry.length >= 6) {
                    alert('You can book maximum 6 seats');
                    return false;
                }
                document.getElementById(`seat_layout_${seatName+busid}`).classList.add('selected');
                seatAry = [...seatAry, {seatName, seatFor, seatFare ,seatBaseFare, seatType}];
            }
            bookingInfo[props.bus_id]['seats'] = seatAry;
            localStorage.setItem('bookingDetails', JSON.stringify(bookingInfo));            
        }else{
            document.getElementById(`seat_layout_${seatName+busid}`).classList.add('selected');
            let seatAry =  {
                seatName : seatName,
                seatFor : seatFor,
                seatFare : seatFare,
                seatBaseFare : seatBaseFare,
                seatType : seatType
            };
            let bookingInfo = {
                [props.bus_id]:{
                    'seats' : [
                        seatAry
                    ]
                },
            };
            //console.log(bookingInfo);
            localStorage.setItem('bookingDetails',JSON.stringify(bookingInfo));
        }
        props.onStateChange(
            JSON.parse(localStorage.getItem('bookingDetails'))[props.bus_id]['seats'] || []
        );
    }
      
    const seatLayout = props.numRowsColumns;
    //console.log(seatLayout);
    let layout = [];
    if (seatLayout) {
        const delimiter = 'X';
        layout = seatLayout.split(delimiter);
    }
    const rows = layout[0] || 0;
    const columns = layout[1] || 0;
    //console.log(rows,columns);
    const seatElements = [];

    const bookingDetails = JSON.parse(localStorage.getItem('bookingDetails')) || {};
    let selectedSeatName = [];
    if(bookingDetails && bookingDetails[props.bus_id] && bookingDetails[props.bus_id]['seats']){
        selectedSeatName = bookingDetails[props.bus_id]['seats'].map((seat) => seat.seatName);
    }
    //console.log(props.seatLayoutData);
    for (let i = 1; i <= columns; i++) {
        const seatColumnElements = [];
        
        for (let j = 1; j <= rows; j++) {
            //console.log(`${i}X${j}`);
            let seatName = '';
            let seatId = '';
            let seatFor = '';
            let seatFare = '';
            let seatType = '';
            let seatBaseFare = '';
            let seatStatus = '';
            let seatClassName = '';
            let seatForClassName = '';
            let seatDisabledDates = '';
            let isDateExists = false;
            let isSeatSelected = false;
            let seatBlockedStatus = 0;
            let isSeatDisabled = false;
            if (Array.isArray(props.seatLayoutData)) {
                const seat = props.seatLayoutData.find(seat => seat.seat_layout_position === `${j}X${i}`);
                //console.log(seat);
                if (seat) {
                    //console.log(`${i}X${j}`);
                    seatName = seat.seat_name;
                    seatId = seat.id;
                    seatFor = seat.seat_for;
                    seatFare = seat.seat_fare;
                    seatType = seat.seat_type;
                    seatStatus = seat.seat_status;
                    seatBaseFare = seat.seat_base_fare;
                    seatDisabledDates = seat.seat_disabled_dates;
                    seatBlockedStatus = seat.blocked_status;
                    if(seatStatus!=1){
                        seatClassName = 'hide';
                        seatForClassName = 'hide';
                        isSeatDisabled = true;
                    }else{
                        if (bookingDetails[props.bus_id]?.journey_details) {
                            /* const formattedDoj = bookingDetails[props.bus_id]['journey_details']['doj'].split('-').reverse().join('-');
                            if(Array.isArray(seatDisabledDates)){
                                isDateExists = seatDisabledDates.includes(formattedDoj);
                            } */
                        }
                        if(isDateExists){
                            if(seatType==1){
                                seatClassName = 'sleeper';
                                seatForClassName = 'sleeper-free-gray booked';
                                isSeatDisabled = true;
                            }else{
                                seatClassName = 'seat';
                                seatForClassName = 'seat-free-blue booked';
                                isSeatDisabled = true;
                            }
                        }else{
                            if(seatObject.hasOwnProperty(seatName) || seatBlockedStatus==1){
                                if(seatObject[seatName]==1){
                                    seatFor = 2;
                                }
                                if(seatType==1){
                                    if(seatFor==2){
                                        seatForClassName = 'sleeper-free-pink booked';
                                        isSeatDisabled = true;
                                    }else{
                                        seatForClassName = 'sleeper-free-gray booked';
                                        isSeatDisabled = true;
                                    }
                                    seatClassName = 'sleeper';
                                }else{
                                    if(seatFor==2){
                                        isSeatDisabled = true;
                                        seatForClassName = 'seat-free-pink booked';
                                    }else{
                                        isSeatDisabled = true;
                                        seatForClassName = 'seat-free-blue booked';
                                    }
                                    seatClassName = 'seat';
                                }
                            }else{
                                if(seatType==1){
                                    if(seatFor==2){
                                        isSeatDisabled = false;
                                        seatForClassName = 'sleeper-free-pink';
                                    }else{
                                        isSeatDisabled = false;
                                        seatForClassName = 'sleeper-free-blue';
                                    }
                                    seatClassName = 'sleeper';
                                }else{
                                    if(seatFor==2){
                                        isSeatDisabled = false;
                                        seatForClassName = 'seat-free-pink';
                                    }else{
                                        isSeatDisabled = false;
                                        seatForClassName = 'seat-free-blue';
                                    }
                                    seatClassName = 'seat';
                                }
                            }
                            if(selectedSeatName.includes(seatName)){
                                seatForClassName += ' selected';
                            }
                        }
                    }
                }
            }
            seatColumnElements.push(
                <li className={`${seatClassName}`} key={`${j}-`}>
                  <div className="">
                    <input type="checkbox" disabled="" />
                  </div>
                  <div className="seatImageDiv">
                    {
                        seatObject.hasOwnProperty(seatName) ? (
                            <input type="checkbox" id={`upper${seatName + props.bus_id}`} />
                        ) : isSeatDisabled===true ? (
                            <input type="checkbox" id={`hideseat${seatName + props.bus_id}`} />
                        ) : (
                            <input
                                type="checkbox"
                                className="hhh"
                                id={`upper${seatName + props.bus_id}`}
                                onClick={() => {
                                    setSeatMob(seatName, seatFor, seatFare, seatBaseFare, seatType, props.bus_id);
                                }}
                            />
                        )
                    }
                    <label
                      className={`${seatForClassName}`}
                      id={`seat_layout_${seatName + props.bus_id}`}
                      title={`Seat No : ${seatName} | Seat Fare : ${seatFare}`}
                      htmlFor={`upper${seatName + props.bus_id}`}
                    >
                      <p>₹{`${seatBaseFare}`}</p>
                    </label>
                  </div>
                </li>
              );
              
        }
        seatElements.unshift(
            <li className="row" key={`${i}-`}>
                <ol className='seats'>
                    {
                        <li className={`seat desk_mode`}>
                            <div className="">
                                <label className="hide"></label>
                            </div>
                        </li>
                    }
                    {seatColumnElements}
                </ol>
            </li>
        );
        //return false;
    }

    return (
        <>
            {seatElements}
        </>
    );
};

export default SeatLayoutMatrixMob;
