import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import { saveAs } from 'file-saver';

const SiteMap = () => {
  /* const apiUrl = process.env.REACT_APP_API_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const apiToken = process.env.REACT_APP_API_TOKEN;
  const [dynamicUrls, setDynamicUrls] = useState([]); */

  /* useEffect(() => {
    axios.get(apiUrl + '/sitemap', {
      headers: {
        'YESBUS_API_KEY': apiKey,
        Authorization: `Bearer ${apiToken}`,
      },
    })
    .then(response => {
      const urlsArray = Array.isArray(response?.data?.urls) ? response.data.urls : [];
      setDynamicUrls(urlsArray);
    })
    .catch(error => {
      console.error('Error fetching dynamic URLs:', error);
    });
  }, [apiUrl, apiKey, apiToken]); */
  

  /* const generateSitemapContent = () => {
    const xmlHeader = '<?xml version="1.0" encoding="UTF-8"?>';
    const urlsetStart = '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">';
    const urlsetEnd = '</urlset>';

    const urlElements = dynamicUrls.map(url => (
      `<url>
         <loc>${url}</loc>
       </url>`
    ));

    return xmlHeader + urlsetStart + urlElements.join('') + urlsetEnd;
  }; */

  return (
    <div>
      {/* {dynamicUrls.length > 0 ? (
        <div>
          <pre>{generateSitemapContent()}</pre>
        </div>
      ) : (
        <p>Loading dynamic URLs...</p>
      )} */}
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      <url>
      <loc>https://www.yesbus.in</loc>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
      </url>
      <url>
      <loc>https://www.yesbus.in/about-us</loc>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
      </url>
      <url>
      <loc>https://www.yesbus.in/contact-us</loc>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
      </url>
      <url>
      <loc>https://www.yesbus.in/cancel-ticket</loc>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
      </url>
      <url>
      <loc>https://www.yesbus.in/find-ticket</loc>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
      </url>
      <url>
      <loc>https://www.yesbus.in/sitemaps/sitemaproutes/sitemap-cities.xml</loc>
      <changefreq>daily</changefreq>
      <priority>0.8</priority>
      </url>
      </urlset>
    </div>
  );
};

export default SiteMap;
