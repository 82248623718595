import { createContext, useContext, useEffect, useReducer } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import reducer from "../reducer/BusReducer";


const BusContext = createContext();

const apiUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;
const apiToken = process.env.REACT_APP_API_TOKEN;

const initialBusState = {
    isLoading: false,
    isError: false,
    errorMsg: '',
    totalBuses: 0,
    buses: [],
};
const AppProvider = ({ children , url}) => {
    
    // check if in url bus '/bus/' exist or '/bus-ticket/' exist
    let slug = '';
    let pageType = '';
    const { sourcedsest, optionaldoj=null, optionalbusid=null } = useParams();
    // console.log(sourcedsest,optionaldoj, optionalbusid);
    if (url.includes('/bus/')) {
      slug = url.replace('/bus/', '');
      pageType = '1';
    }else if(url.includes('/bus-ticket/')){
      slug = url.replace('/bus-ticket/', '');
      pageType = '2';
    } else if(url.includes('/bus-service/')){
      slug = url.replace('/bus-service/', '');
      pageType = '3';
    }

    const [sourceCitySlug, destCitySlug] = sourcedsest.split('-to-');
    // const [destCitySlug, slugdateSlug] = destCityWithDateSlug.split('/');
    // console.log(sourceCitySlug);
    // let destCitySlug = '' 
    let slugdateSlug = optionaldoj
    const today = new Date();
    let dateSlug = '';
    if(pageType==3){
      dateSlug = slugdateSlug || `${today.getDate() + 1}-${today.getMonth() + 1}-${today.getFullYear()}`;
    }else{
      // dateSlug = slugdateSlug || `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
      dateSlug = slugdateSlug || `${today.getDate() + 1}-${today.getMonth() + 1}-${today.getFullYear()}`;
    }
    

    const [state, dispatch] = useReducer(reducer, initialBusState);
    const getBusList = async () => {
      // const dateSlug = slugdateSlug || `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
      const dateSlug = slugdateSlug || `${today.getDate() + 1}-${today.getMonth() + 1}-${today.getFullYear()}`;
      try {
        // const res =  await axios.get(apiUrl+'/bus/list?source='+sourceCitySlug+'&destination='+destCitySlug+'&doj='+dateSlug+'&page=1', { 
        // const res =  await axios.get(apiUrl+'/bus/test-list?source='+sourceCitySlug+'&destination='+destCitySlug+'&doj='+dateSlug+'&page=1', { 
        const res =  await axios.get(apiUrl+'/bus/list?source='+sourceCitySlug+'&destination='+destCitySlug+'&doj='+dateSlug+'&page=1', { 
          headers: {
            'YESBUS_API_KEY': apiKey,
            Authorization: `Bearer ${apiToken}`,
          }
        });
        const buses = await res.data;
        if(buses.status===200){
          dispatch({ type: "SET_API_DATA", payload: buses.data });
        }else{
          dispatch({ type: "API_RESPONSE_ERROR", errorMsg: buses.message, isError:true });
        }
      } catch (error) {
        dispatch({ type: "API_ERROR" });
      }
    }
    const getBusListByBusId = async () => {
      
      let busId = optionalbusid
      
      try {
        const res =  await axios.get(apiUrl+'/bus/individual-bus?source='+sourceCitySlug+'&destination='+destCitySlug+'&doj='+dateSlug+'&bus_id='+busId+'&page=1', {
          headers: {
            'YESBUS_API_KEY': apiKey,
            Authorization: `Bearer ${apiToken}`,
          }
        });
        const buses = await res.data;
        if(buses.status===200){
          dispatch({ type: "SET_API_DATA", payload: buses.data });
        }else{
          dispatch({ type: "API_RESPONSE_ERROR", errorMsg: buses.message, isError:true });
        }
      } catch (error) {
        dispatch({ type: "API_ERROR" });
      }
    }
    const getInfinityBusListByBusId = async () => {
      let busId = optionalbusid
      try {
        const res =  await axios.get(apiUrl+'/infinity-bus/individual-bus?source='+sourceCitySlug+'&destination='+destCitySlug+'&doj='+dateSlug+'&company_id='+busId+'&page=1', {
          headers: {
            'YESBUS_API_KEY': apiKey,
            Authorization: `Bearer ${apiToken}`,
          }
        });
        const buses = await res.data;
        if(buses.status===200){
          dispatch({ type: "SET_API_DATA", payload: buses.data });
        }else{
          dispatch({ type: "API_RESPONSE_ERROR", errorMsg: buses.message, isError:true });
        }
      } catch (error) {
        dispatch({ type: "API_ERROR" });
      }
    }
    const getBuses = async () => {
      dispatch({ type: "SET_LOADING" });
      if(pageType==1){
        getBusList();
      }else if(pageType==2){
        getBusListByBusId();
      }else if(pageType==3){
        getInfinityBusListByBusId();
      }
    };
  
    useEffect(() => {
      getBuses();
    }, []);
    useEffect(() => {
      getBuses();
      localStorage.removeItem('bookingDetails');
      localStorage.removeItem('bookingId');
      localStorage.removeItem('orderId');
    }, [dateSlug, destCitySlug, sourceCitySlug]);
    return (
      <BusContext.Provider value={{...state}}>
        {children}
      </BusContext.Provider>
    );
};
// custom hooks
const useBusContext = () => {
    return useContext(BusContext);
};


export { AppProvider, BusContext, useBusContext };
  